'use strict';

Gri.module({
  name: 'language-alpha',
  ieVersion: null,
  $el: $('.language-alpha'),
  container: '.language-alpha',
  fn: function () {
    
  }
});
